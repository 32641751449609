<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div v-if="minutes <= 30" class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->
          <!-- <b-img
            v-if="!($store.state.auth.configData !== '' && $store.state.auth.configData.name !== 'BeOnTrips' && !imageError)"
            :src="$store.state.auth.configData.clubLogo"
            alt="logo"
            width="180"
            @error="noImage"
          />
          <Logo v-else/> -->
          <Logo />

          <!-- <h2 class="brand-text text-primary ml-1">
            {{ name }}
          </h2> -->
        </b-link>

        <b-card-title class="mb-1 title">
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2 card-text">
          Your new password must be different from previously used passwords
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|min:8|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="form.new_password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer togglePassword1Visibility"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|min:8|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="form.confirm_password"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer togglePassword2Visibility"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="loading ? disabled : !disabled"
            >
              Set New Password
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{ name: 'login' }">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>
        <p style="width:max-content" class="clearfix mb-0">
          <span class="float-md-left d-block d-md-inline-block mt-2">
            COPYRIGHT © {{ new Date().getFullYear() }}
            <b-link class="ml-25">{{ name }}</b-link>
            <span class="d-none d-sm-inline-block">, All Rights Reserved</span>
          </span>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
    <link-expired-component v-if="minutes > 30" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { name } from "@/helpers/constant";
import {
	BCard,
	BCardTitle,
	BCardText,
	BForm,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BLink,
	BFormInput,
	BButton,
  BImg,
} from "bootstrap-vue";
// import { required } from '@validations'
import { required } from "vee-validate/dist/rules";
import Logo from "@/layouts/frontend-logo/Logo.vue";
import VueJwtDecode from "vue-jwt-decode";
import LinkExpiredComponent from "@/views/error/LinkExpired.vue";

import { password } from "@core/utils/validations/validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";

export default {
	components: {
		Logo,
		BCard,
		BButton,
		BCardTitle,
		BCardText,
		BForm,
		BFormGroup,
		BInputGroup,
		BLink,
		BFormInput,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		LinkExpiredComponent,
    BImg,
	},
	data() {
		return {
			form: {
				new_password: "",
				confirm_password: ""
			},
			userEmail: "",
			loading: false,
			disabled: true,

			// validation
			required,
			password,

			// Toggle Password
			password1FieldType: "password",
			password2FieldType: "password",
			name: (this.$store.state.auth?.configData && this.$store.state.auth?.configData?.name) ? this.$store.state.auth?.configData?.name : 'Beontrips',
			minutes: "",
      imageError: false
		};
	},
	computed: {
		password1ToggleIcon() {
			return this.password1FieldType === "password" ? "EyeOffIcon" : "EyeIcon";
		},
		password2ToggleIcon() {
			return this.password2FieldType === "password" ? "EyeOffIcon" : "EyeIcon";
		}
	},

	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		const { iat } = VueJwtDecode.decode(urlParams.get("token"));
		const currentTime = new Date().getTime() / 1000;
		this.minutes = (currentTime - iat) / 60;
	},
	methods: {
		togglePassword1Visibility() {
			this.password1FieldType =				this.password1FieldType === "password" ? "text" : "password";
		},
		togglePassword2Visibility() {
			this.password2FieldType =				this.password2FieldType === "password" ? "text" : "password";
		},
		validationForm() {
			this.$refs.simpleRules.validate().then(success => {
				const urlParams = new URLSearchParams(window.location.search);

				const token = urlParams.get("token");
				if (success) {
					this.loading = true;
					axios
						.post(`auth/reset-password/${token}}`, {
							new_password: this.form.new_password,
							confirm_password: this.form.confirm_password
						})
						.then(res => {
							this.loading = false;
							this.$router.push("/login");
							this.$toast({
								component: ToastificationContent,
								props: {
									title: res.data.message,
									icon: "CheckIcon",
									variant: "success"
								}
							});
						})
						.catch(error => {
							setTimeout(() => {
								this.loading = false;
							}, 3000);
							this.$toast({
								component: ToastificationContent,
								props: {
									title: error.response
										? error.response.data.message
										: "Service is not available. Please try again later",
									icon: "EditIcon",
									variant: "danger"
								}
							});
						});
				}
			});
		},
    noImage() {
			this.imageError = true
		}
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
